import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { FiCalendar, FiMapPin } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import Wrapper from './eventsWrapper';
import fallbackImage from '../../assets/fallback-img.jpg';

// Function to construct the image URL
const getImageUrl = (imageId) => `https://drive.google.com/thumbnail?id=${imageId}&sz=w1000`;

const tmpEvents = [
    {
        id: "1",
        title: "Square Dancing",
        name: "Bachata Live @ The Square",
        start_time: "2024-08-26T19:00:00",
        place: {
            name: "The Square",
            location: "Bournemouth, Dorset"
        },
        category: "SBK Social",
        imageId: "1QcJTzuode_bt67_SmBPEjPbnkbA2zbvm",
        url: "https://fb.me/e/7ct5NhnxC"
    },
    {
        id: "2",
        title: "Weekly So Bachata Night",
        name: "So Bachata Re-Opening Event",
        start_time: "2024-09-17T19:30:00",
        place: {
            name: "Bar So",
            location: "Bournemouth, Dorset"
        },
        category: "Bachata Classes",
        imageId: "13CZ5Ia2sdoHajfrNPscpQ19jKmzbTPtr",
        url: "https://fb.me/e/1M1g9cDY6"
    },
    {
        id: "3",
        title: "Weekly So Bachata Night",
        name: "Test Event",
        start_time: "2024-08-20T19:30:00",
        place: {
            name: "Bar So",
            location: "Bournemouth, Dorset"
        },
        category: "type 1",
        imageId: "13CZ5Ia2sdoHajfrNPscpQ19jKmzbTPtr",
        url: "https://fb.me/e/1M1g9cDY6"
    }
];



// Function to load an image and return a promise
const loadImage = (src) =>
  new Promise((resolve, reject) => {
    if (!src) {
      reject(new Error('Image URL is undefined'));
      return;
    }
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(src);
    img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
  });


const EventModal = ({ event, onClose }) => {
  if (!event) return null; // Do not render if there's no event

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>X</button>
        <img src={`https://drive.google.com/thumbnail?id=${event.imageId}&sz=w1000`} alt="Event Full View" className="modal-image" />
        <div className="modal-info">
          <h3 className="event-name">{event.name}</h3>
          <div className="event-date-location">
              <FiCalendar className="icon" />
              <p className="event-date">{format(new Date(event.start_time), 'EEEE do MMMM, p')}</p>
          </div>
          <div className="event-date-location">
              <FiMapPin className="icon" />
              <p className="event-location">
                  {event.place.name}, {event.place.location}
              </p>
          </div>
            <a href={event.url} target="_blank" rel="noopener noreferrer">
              <button className="modal-btn">View on Facebook</button>
            </a>
        </div>
      </div>
    </div>
  );
};


const HomeEvents = () => {
    const [data, setData] = useState([]);
    const [events, setEvents] = useState([]);
    const [loadedData, setLoadedData] = useState(false)
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [buttonText, setButtonText] = useState('Load more');
    const [selectedEvent, setSelectedEvent] = useState('');
    const eventsGridRef = useRef(null); // Create a ref for the events grid
    const navigate = useNavigate(); // Hook for navigation

    const openModal = (event) => {
      setSelectedEvent(event); // Set the clicked event
    };
  
    const closeModal = () => {
      setSelectedEvent(null); // Close the modal
    };
    const filterFutureEvents = (events) => {
      const now = new Date(); // Get the current date and time

      return events.filter(event => {
          const eventDate = new Date(event.start_time);
          return eventDate >= now; // Return only events that are today or in the future
      });
    };

    useEffect(() => {
      fetch('/fetch-json')
        .then(response => response.json())
        .then(fetchedData => {
          if (Array.isArray(fetchedData)) {
            //exclude past events
            const futureEvents  = filterFutureEvents(fetchedData)
            setData(futureEvents)
            setEvents(futureEvents.slice(0, 3));
            setLoadedData(true);

          } else {
            console.error('Fetched data is not an array:', fetchedData);
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoadedData(false);
        });

        // const futureEvents  = filterFutureEvents(tmpEvents)
        // setEvents(futureEvents.slice(0, 3));
        // setLoadedData(true);
    }, []);

    const loadMoreEvents = () => {
        if (buttonText === 'Load more' && events.length === 3) {
            // If currently showing initial 3 events, show 3 more events
            setEvents(data.slice(0, 6)); // Show 6 events
            setButtonText('See all events'); // Change button text to prompt for navigation

            // Scroll only when loading more events, not when navigating away
            // setTimeout(() => {
            //     const eventsPosition = eventsGridRef.current.getBoundingClientRect().top + window.pageYOffset;
            //     window.scrollTo({ top: eventsPosition, behavior: 'smooth' });
            // }, 0);
        } else if (buttonText === 'See all events') {
            // If button text is 'See all events', navigate to the events page
            navigate('/events');
        } else if (buttonText === 'Load more' && events.length < 4) {
            navigate('/events');

        }
    };

    useEffect(() => {
        // Check all images for loading
        const checkImages = async () => {
          try {
            await Promise.all(
              data.map((event) => {
                const imageUrl = getImageUrl(event.imageId);
                return loadImage(imageUrl).catch((error) => {
                  console.error(error.message);
                  return fallbackImage;
                });
              })
            );
            setImagesLoaded(true);
          } catch (error) {
            console.error('Error loading some images:', error);
            setImagesLoaded(false);
          }
        };

        if (data.length > 0) {
          checkImages();
        }
    }, [data]);



    // const getImage = (id) => {
    //     const images = [image1, image2, image3, image4, image5, image6];
    //     // Simply return an image by modulo index to cycle through the available images
    //     return images[(id - 1) % images.length];
    // };

    return (
        <Wrapper>
            <h2>UPCOMING EVENTS</h2>
            {imagesLoaded && loadedData ? (
            <>
            <div className="events-grid" ref={eventsGridRef}>

                  {events.map((event) => (
                    <div key={event.id} className="event-card" onClick={() => openModal(event)}>
                        <div className="event-image-container">
                            <img src={`https://drive.google.com/thumbnail?id=${event.imageId}&sz=w1000`} alt="Event" className="event-image" />
                        </div>
                        <div className="event-info">
                            <h3 className="event-name">{event.name}</h3>
                            <div className="event-date-location">
                                <FiCalendar className="icon" />
                                <p className="event-date">{format(new Date(event.start_time), 'EEEE do MMMM, p')}</p>
                            </div>
                            <div className="event-date-location">
                                <FiMapPin className="icon" />
                                <p className="event-location">
                                    {event.place.name}, {event.place.location}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
              </div>
              <div className="button-wrapper">
                  <button onClick={loadMoreEvents}>{buttonText}</button>
              </div>
            </>
            ): (
                  <Spinner />

                )
              }

          {/* Modal for full event view */}
          <EventModal event={selectedEvent} onClose={closeModal} />
        </Wrapper>
    );
};

export default HomeEvents;
