import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Navbar, Footer, Sidebar } from './components';
import './App.css';
import EventsPage from './pages/EventsPage';
import { Home, Why, Ladies } from './pages';

const ScrollToTop = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
};

function App() {
    return (
        <Router>
            <ScrollToTop /> {/* This component will reset the scroll position on route change */}
            <Navbar />
            <Sidebar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/why" element={<Why />} />
                <Route path="/ladies" element={<Ladies />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
