import React from 'react'
import { GiCompass, GiDiamondHard, GiStabbedNote } from 'react-icons/gi'
import review1 from '../assets/review1.png'
import review2 from '../assets/review2.png'
import review3 from '../assets/review3.png'
import review4 from '../assets/review4.png'
import review5 from '../assets/review5.png'
import review6 from '../assets/review6.png'

export const links = [
  {
    id: 2,
    text: 'why learn bachata',
    url: '/why',
  },
  {
    id: 3,
    text: 'classes and events',
    url: '/events',
  },
  {
    id: 4,
    text: 'ladies styling',
    url: '/ladies',
  }
]


export const partners = [
  {
    id: 1,
    text: 'Salsa Ro',

    url: 'https://drive.google.com/thumbnail?id=1y-YYfOiCBpQ3E1EjX0X077HoDd7nbY3g&sz=w1000'
  },
  {
    id: 2,
    text: 'Musictronic',
    url: 'https://drive.google.com/thumbnail?id=1NAUbejmpDjBYwBVjwAlGybZ0RMoZH276&sz=w1000'
  },
  {
    id: 3,
    text: 'SBK Weymouth',
    url: 'https://drive.google.com/thumbnail?id=1FT-4VJEIZoq_hi6v0GJhKK0tIkq-9jrf&sz=w1000'
  },
  {
    id: 4,
    text: 'Kings',
    url: 'https://drive.google.com/thumbnail?id=1KV4ZqVjFWOdbWpl9RhwjJI0Uf3AnEQCL&sz=w1000'
  },
  {
    id: 5,
    text: 'Bailando',
    url: 'https://drive.google.com/thumbnail?id=1jOllt5q4iL15bZz5htyA_LBDC3r0-OrO&sz=w1000'
  },
  {
    id: 5,
    text: 'Arosadesigns',
    url: 'https://drive.google.com/thumbnail?id=18P7Pjq1GfYO170RGswXm1NIn9lEBq01e&sz=w1000'
  },
  {
    id: 5,
    text: 'BarSo',
    url: 'https://drive.google.com/thumbnail?id=1Umk7xolDfzB4BaKFEkmin4bM0Z2QJ87Z&sz=w1000'
  },
  {
    id: 5,
    text: 'Picnic Park Deli',
    url: 'https://drive.google.com/thumbnail?id=1RVrpO3Z-a6azY67jAuwt6R3oZJ6LmCch&sz=w1000'
  }
]

export const faqs = [
  {
    id: 1,
    question: '"Can I join if I have never done Bachata?"',
    answer: '"Of course you can! We offer different levels of classes from beginners to advanced."'

  },
  {
    id: 2,
    question: '"Do I need to bring a partner?"',
    answer: '"No partner is required as we rotate partners during the lesson. If you bring your own partner and would like to stay together, it is totally acceptable."'

  },
  {
    id: 3,
    question: '"What should I wear?"',
    answer: '"We want students to feel comfortable during the class and avoid injuries so the outfit and footwear should be appropriate."'

  },
  {
    id: 4,
    question: '"What payment system do you offer?"',
    answer: '"You can pay as you go via cash or card payment as well as we do offer class passes that can be purchased on the night. Students also do get additional discounts."'

  },
  {
    id: 5,
    question:'"Do I need to book in advance?"',
    answer: `"You don't have to book in advance unless it is a specific course or workshop but that will be advertised prior."`
  },
  {
    id: 6,
    question:'"What if I miss a class?"',
    answer: `"You don't have to worry about missing a week - we teach a different dance routine each time."`
  }

]

export const services = [
  {
    id: 1,
    icon: <GiCompass />,
    title: 'mission',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, ea. Perferendis corrupti reiciendis nesciunt rerum velit autem unde numquam nisi',
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: 'vision',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, ea. Perferendis corrupti reiciendis nesciunt rerum velit autem unde numquam nisi',
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: 'history',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, ea. Perferendis corrupti reiciendis nesciunt rerum velit autem unde numquam nisi',
  },
]

export const reviews = [
  {
    id: 1,
    image: review1,
      //'https://drive.google.com/thumbnail?id=1vIY82z9pddCxJH-aHENSDNO8m-HtTgkj&sz=w1000',
    name: 'Person1',
  },
  {
    id: 2,
    image: review2,
      //'https://drive.google.com/thumbnail?id=1vLn8Zm9Cbie0AXJK4a5enrIXHhphE2ny&sz=w1000',
    name: 'Person2',
  },
  {
    id: 3,
    image: review3,
      //'https://drive.google.com/thumbnail?id=1vHwsAcG8s4E2t7zblVUeiN6a3tAoeQhx&sz=w1000',
    name: 'Person3',
  },
  {
    id: 4,
    image: review4,
        //'https://drive.google.com/thumbnail?id=1vCdNWYot9g-mkxDsWTqtxzOulV3BjKgd&sz=w1000',
    name: 'Person4',
  },
  {
    id: 5,
    image: review5,
        //'https://drive.google.com/thumbnail?id=1vBFQJ_N8zNI4aqVLiT2DY3A_ITgbnEsL&sz=w1000',
    name: 'Person5',
  },
  {
    id: 6,
    image: review6,
        //'https://drive.google.com/thumbnail?id=144_vGlbMdIGip5BtDkVpSJYkeph4C1_Y&sz=w1000',
    name: 'Person6',
  },
];

export const products_url = 'https://course-api.com/react-store-products'

export const single_product_url = `https://course-api.com/react-store-single-product?id=`
